.recommended-influncer-card {
  margin-bottom: 7px;
  padding: 14px;
  background: #2D2D30;
  border-radius: 6px;

  .status-badge {
    position: absolute;
    top: 8px;
    right: 8px;
  }

  .profile-image-wrapper {
    height: 58px;
    width: 58px;
    border-radius: 50px;
    margin-right: 14px;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50px;
    }
  }

  .name {
    font-weight: 600;
    font-size: 15px;
    line-height: 115%;
    color: #FBFBFB;
    margin-bottom: 4px;
  }

  .influencer-type {
    font-weight: 600;
    font-size: 10px;
    line-height: 135%;
    color: #1DA1F2;

    .social-icon {
      max-width: 12px;
      margin-right: 4px;
    }
  }

  .rating-container {
    width: 48px;
    height: 28px;
    background: #11BC22;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 700;
    font-size: 10px;
    line-height: 115%;
    color: #FFFFFF;
    padding: 0 8px;

    svg {
      margin-right: 3px;
    }
  }

  .matrix-wrapper {
    margin-top: 18px;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;

    .label {
      font-weight: 500;
      font-size: 11px;
      line-height: 150%;
      color: #FFFFFF;
      opacity: 0.95;
    }

    .value {
      font-weight: 700;
      font-size: 15px;
      line-height: 150%;
      color: #FFFFFF;
      opacity: 0.95;
    }
  }
}