.content-calender {
  background: #151515;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 29px 0 26px 0;

    .back-button {
      width: 41px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 1px solid #FFFDD6;
      border-radius: 11px;
    }
  }

  .title {
    font-weight: 600;
    font-size: 22px;
    line-height: 115%;
    color: #FBFBFB;
    margin-bottom: 19px;
  }

  .border-bottom {
    width: 100%;
    opacity: 0.32;
    border: 1px solid rgba(255, 253, 214, 0.28);
    margin: 34px 0 26px 0;
  }

  .selected-date {
    font-weight: 600;
    font-size: 14px;
    line-height: 124.5%;
    color: #F7F6FB;
    opacity: 0.99;
  }

  .platform-tab {
    background: #2D2D2D;
    border-radius: 6px;
    padding: 7px 8px;
    font-weight: 700;
    font-size: 10px;
    line-height: 124.5%;
    color: #F7F6FB;
    opacity: 0.99;
    display: flex;
    align-items: center;
    margin-right: 7px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      height: 10px;
      max-width: 12px;
      margin-right: 4px;
    }
  }
}