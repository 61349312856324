.status-tabs {
  display: flex;
  justify-content: space-between;
  align-items: center;
  overflow-x: scroll !important;
  margin-bottom: 24px;
  width: 100%;
  -webkit-overflow-scrolling: touch;

  &::-webkit-scrollbar {
    display: none;
  }

  .tab {
    font-weight: 500;
    font-size: 16px;
    line-height: 124.5%;
    color: #F7F6FB;
    opacity: 0.8;
    margin: 0 7px;
    cursor: pointer;

    &:first-child {
      margin-left: 0;
    }
  }

  .active-tab {
    font-weight: 600;
    font-size: 16px;
    line-height: 124.5%;
    color: #A482FF;
  }
}