.pending-campaign-page {
   padding-top: 84px;
   padding-bottom: 28px;

   .pending-campaign-card-wrapper {
      padding-bottom: 20px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      margin-bottom: 30px;
      display: flex;
      align-items: center;

      .company-logo {
         height: 85px;
         width: 85px;
         border-radius: 21px;
         margin-right: 14px;
      }

      .name {
         font-weight: 600;
         font-size: 16px;
         line-height: 115%;
         color: #FBFBFB;
      }

      .date {
         font-weight: 500;
         font-size: 12px;
         line-height: 135%;
         color: #CFCFD0;
         margin: 5px 0 9px 0;
      }
   }

   .payment-status-card {
      height: 78px;
      background: #2D2D30;
      border-radius: 8px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 16px;
      margin-bottom: 30px;

      .amount {
         font-weight: 600;
         font-size: 20px;
         line-height: 124.5%;
         color: #F7F6FB;
         margin-bottom: 6px;
      }

      .date {
         font-weight: 500;
         font-size: 10px;
         line-height: 115%;
         color: #FFFFFF;
      }
   }

   .title {
      font-weight: 600;
      font-size: 19px;
      line-height: 115%;
      color: #FFFFFF;
      position: relative;
      width: fit-content;

      &::after {
         position: absolute;
         content: '';
         right: -84px;
         top: 50%;
         transform: translateY(-50%);
         height: 2px;
         width: 72px;
         background: linear-gradient(89.96deg, #FBFBFB -7.96%, rgba(251, 251, 251, 0) 81.76%);
      }
   }
}