$breakpoints: (
    "xs": 0,
    "sm": 30rem,
    "md": 768px,
    "lg": 1024px,
    "xl": 75rem,
);

@mixin xs {
  @media (min-width: map-get($breakpoints, "xs")) {
    @content;
  }
}

@mixin sm {
  @media (min-width: map-get($breakpoints, "sm")) {
    @content;
  }
}

@mixin md {
  @media (min-width: map-get($breakpoints, "md")) {
    @content;
  }
}

@mixin lg {
  @media (min-width: map-get($breakpoints, "lg")) {
    @content;
  }
}

@mixin xl {
  @media (min-width: map-get($breakpoints, "xl")) {
    @content;
  }
}

@mixin breakpoint($bp: 0) {
  @media (min-width: $bp) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: map-get($breakpoints, "md")) {
    @content;
  }
}
