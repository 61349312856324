.custom-event-calender {

   .react-datepicker,
   .react-datepicker__month-container {
      width: 100%;
   }

   .react-datepicker__month {
      margin: auto 0;
   }

   .react-datepicker__day-names {
      display: flex;
      justify-content: space-between;
      margin-top: 15px;

      >div {
         height: 36px;
         width: 36px;
      }
   }

   .react-datepicker__current-month {
      font-weight: 600;
      font-size: 17px;
      line-height: 18px;
      color: #FFFFFF;
      opacity: 0.9;
   }

   .react-datepicker__header,
   .react-datepicker {
      background: transparent;
      color: #FFF;
      border: none;
      padding: 8px 0 0;
   }

   .react-datepicker__navigation-icon::before {
      top: 20px;
   }

   .react-datepicker__navigation-icon--previous::before {
      right: -5px;
   }

   .react-datepicker__navigation-icon--next::before {
      left: -5px;
   }

   .react-datepicker__week {
      display: flex;
      justify-content: space-between;
   }

   .react-datepicker__day-name {
      color: #FFF;
      opacity: 0.4;
      font-weight: 500;
      font-size: 13px;
      line-height: 17px;
      color: #FFFFFF;
      mix-blend-mode: normal;
      opacity: 0.4;
   }

   .react-datepicker__day,
   .react-datepicker__time-name {
      font-style: normal;
      font-weight: 500;
      font-size: 13px;
      line-height: 18px;
      color: #FFFFFF;
      height: 36px;
      width: 36px;
      border-radius: 50%;
      margin: 2px 0;
      display: flex;
      align-items: center;
      justify-content: center;
   }

   .react-datepicker__day--outside-month {
      color: rgba(115, 116, 117, 1);
   }

   .react-datepicker__day--selected,
   .react-datepicker__day--keyboard-selected {
      background: #6B37F4 !important;
      border-radius: 50% !important;
      color: #FFF;
   }

   .react-datepicker__day--keyboard-selected,
   .react-datepicker__day:hover {
      background-color: #a081ef !important;
   }
}