.project-profile-page {
  min-height: 100vh;
  background: #151515;
  position: relative;
  padding-bottom: 28px;

  &::after {
    position: absolute;
    content: '';
    left: -284px;
    bottom: 100%;
    width: 508px;
    height: 508px;
    background: #6B37F4;
    filter: blur(300px);
    z-index: 1;
  }

  .profile-page-px {
    padding-left: 17px;
    padding-right: 17px;
  }

  .profile-banner-wrapper {
    position: relative;
    height: 174px;
    width: 100%;
    margin-bottom: 26px;

    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(175.85deg, #000000 1.32%, rgba(0, 0, 0, 0) 67.45%), linear-gradient(360deg, #19171E 3.07%, rgba(25, 23, 30, 0) 26.38%);
      transform: matrix(-1, 0, 0, 1, 0, 0);
      z-index: 2;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .back-button {
      position: absolute;
      left: 17px;
      top: 29px;
      height: 36px;
      width: 38px;
      opacity: 0.7;
      border: 1px solid #FFFDD6;
      border-radius: 11px;
      display: flex;
      align-items: center;
      justify-content: center;
      z-index: 3;

      img {
        height: 12px;
        width: 12px;
      }
    }

    .project-logo-wrapper {
      position: absolute;
      left: 21px;
      bottom: -65px;
      width: 121px;
      height: 133px;
      border: 4px solid #1E192D;
      background: #2c2a34;
      z-index: 3;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .edit-button {
    height: 44px;
    width: 43px;
    background: #030622;
    border: 1px solid rgba(101, 99, 221, 0.19);
    border-radius: 9px;
    margin: 0 4px;
    flex-shrink: 0;

    &:focus {
      outline: none;
    }

    &.active {
      background: rgba(12, 74, 189, 0.6);
    }

  }

  .profile-edit-input {
    background: transparent;
    border: none;

    &:focus {
      outline: none;
    }

    &.textarea {
      width: 100%;
      height: 120px;
    }
  }

  // .follow-button{
  //   border: 1px solid #FFFDD6;
  //   border-radius: 6px;
  //   height: 38px;
  //   padding: 10px 15px;
  //   outline: none;
  //   background: transparent;
  //   font-weight: 600;
  //   font-size: 13px;
  //   line-height: 140%;
  //   display: flex;
  //   align-items: center;
  //   letter-spacing: 0.01em;
  //   color: #FFFDD6;

  //   &:focus{
  //     outline: none;
  //   }

  //   svg{
  //     margin-left: 7px;
  //   }
  // }
}