.question-with-answer{
  margin-bottom: 28px;
  
  .question-number{
    font-weight: 600;
    font-size: 16px;
    line-height: 124.5%;
    color: #FFF;
    margin-right: 5px;
  }
  .question{
    font-weight: 600;
    font-size: 16px;
    line-height: 124.5%;
    color: #FFF;
  }
  .answer{
    font-weight: 500;
    font-size: 14px;
    line-height: 141%;
    color: #FFFFFF;
    opacity: 0.85;
    margin-top: 9px;
  }
}