.influencer-social-modal {
   position: fixed;
   top: 0;
   left: 0;
   height: 100vh;
   width: 100%;
   background: rgba(24, 4, 75, 0.21);
   backdrop-filter: blur(29px);
   display: grid;
   place-items: center;
   padding: 8px;
   z-index: 11;
   opacity: 0;
   visibility: hidden;
   transition: all 0.3s ease;

   &.active {
      opacity: 1;
      visibility: visible;
   }

   .card {
      position: relative;
      background: #2D2D30;
      box-shadow: 0px 21px 145px rgba(255, 253, 214, 0.16);
      border-radius: 14px;
      padding: 32px 16px;
      width: 100%;
      max-width: 600px;

      .close-modal-button {
         width: 24px;
         height: 24px;
         background: #505050;
         display: flex;
         align-items: center;
         justify-content: center;
         position: absolute;
         top: 15px;
         right: 15px;
         border-radius: 50%;
         border: none;
         cursor: pointer;
      }

      .title {
         font-weight: 600;
         font-size: 16px;
         line-height: 115%;
         color: #F0F0F0;
         position: relative;
         width: fit-content;
         margin-bottom: 17px;

         &::after {
            position: absolute;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            right: -84px;
            width: 72px;
            height: 2px;
            background: linear-gradient(89.96deg, #FBFBFB -7.96%, rgba(251, 251, 251, 0) 81.76%);
         }
      }

      .social-details-card {
         display: grid;
         grid-template-columns: repeat(2, 1fr);
         row-gap: 7px;
         column-gap: 4px;
         margin-bottom: 28px;

         .card {
            width: 100%;
            height: 75px;
            background: #1F1E21;
            border-radius: 8px;
            padding: 18px 14px;

            .platform-name {
               font-weight: 500;
               font-size: 11px;
               line-height: 103%;
               color: #FFFFFF;
               opacity: 0.8;
               margin-left: 4px;
            }

            .follower-number {
               font-weight: 700;
               font-size: 18px;
               line-height: 115%;
               color: #FFFDD6;
               margin-top: 8px;
            }

            svg {
               max-height: 10px;
               max-width: 12px;
            }
         }
      }

      .density-map {
         background: #1F1E21;
         border-radius: 8px;
         height: 192px;
         width: 100%;
         margin-bottom: 28px;
      }

      .language-wrapper {
         row-gap: 8px;
         column-gap: 7px;

         .language {
            background: #1F1E21;
            border-radius: 8px;
            height: 38px;
            padding: 10px 18px;
            font-weight: 500;
            font-size: 16px;
            line-height: 115%;
            color: #FFFDD6;
         }
      }
   }
}