.influencer-questions {
   padding-top: 84px;
   padding-bottom: 28px;

   .add-question-container {
      padding-bottom: 17px;
      margin-bottom: 22px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);

      .title {
         font-weight: 600;
         font-size: 24px;
         line-height: 115%;
         color: #FFFFFF;
         max-width: 170px;
      }

      .add-question-button {
         display: flex;
         align-items: center;
         justify-content: center;
         padding: 10px 19px;
         background: transparent;
         border: 1px solid #5E5E5E;
         border-radius: 6px;
         margin-left: 15px;
         font-weight: 600;
         font-size: 12px;
         line-height: 150%;
         letter-spacing: 0.01em;
         color: #CECECE;
         cursor: pointer;

         &:focus {
            outline: none;
         }

         svg {
            margin-right: 5px;
         }
      }
   }

   .questions {

      .question-container {
         margin-bottom: 25px;

         &:last-child {
            margin-bottom: 44px;
         }

         .question-number {
            font-weight: 600;
            font-size: 16px;
            line-height: 124.5%;
            color: #FFF;
         }

         .question {
            font-weight: 600;
            font-size: 16px;
            line-height: 124.5%;
            color: #FFF;
            height: fit-content;
            padding: 0 5px 0 5px;
            width: 100%;
            background: transparent;
            border: none;
            outline: none;

            &:focus {
               border: none;
               outline: none;
            }
         }

         .answer-container {
            margin-top: 13px;

            .answer-input {
               background: #0F0D0D;
               border: 1px solid rgba(248, 248, 248, 0.19);
               border-radius: 9px;
               flex-grow: 1;
               font-weight: 500;
               font-size: 12px;
               line-height: 150%;
               color: #FFFFFF;
               opacity: 0.8;
               padding: 13px 19px;
               height: 44px;
               transition: all 0.2s ease;

               &::-webkit-outer-spin-button,
               &::-webkit-inner-spin-button {
                  -webkit-appearance: none;
                  margin: 0;
               }

               /* Firefox */
               &[type=number] {
                  -moz-appearance: textfield;
               }

               &:focus {
                  border: 1px solid #FFF;
                  outline: none;
               }

               &::placeholder {
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 150%;
                  color: #FFFFFF;
               }
            }

            .edit-button {
               height: 44px;
               width: 43px;
               background: #030622;
               border: 1px solid rgba(101, 99, 221, 0.19);
               border-radius: 9px;
               margin: 0 4px;
               flex-shrink: 0;

               &:focus {
                  outline: none;
               }

               // .edit-option-wrapper {
               //    position: absolute;
               //    bottom: -88px;
               //    right: 0;
               //    height: 82px;
               //    width: 126px;
               //    background: #1D1D1D;
               //    border: 1px solid rgba(255, 253, 214, 0.5);
               //    border-radius: 8px;
               //    padding: 13px 12px;
               //    display: flex;
               //    flex-direction: column;
               //    z-index: 11;

               //    .option {
               //       font-weight: 500;
               //       font-size: 12px;
               //       line-height: 124.5%;
               //       color: #FFFFFF;
               //       text-align: left;
               //       height: 100%;
               //       display: flex;
               //       border-bottom: 1px solid rgba(255, 255, 255, 0.16);

               //       &:last-child {
               //          border-bottom: none;
               //          align-items: end;
               //       }
               //    }
               // }
            }

            .delete-button {
               height: 44px;
               width: 43px;
               background: #220303;
               border: 1px solid rgba(221, 99, 99, 0.19);
               border-radius: 9px;
               flex-shrink: 0;

               &:focus {
                  outline: none;
               }
            }
         }
      }
   }

   .generate-link-button {
      svg {
         margin-right: 5px;
      }
   }
}