.bottom-navbar{
  position: sticky;
  bottom: 28px;
  margin-top: 14px;
  background: #131314;
  border: 1px solid rgba(164, 130, 255, 0.16);
  box-shadow: 0px -4px 80px rgba(2, 41, 100, 0.08);
  border-radius: 20px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 15px 25px 12px 15px;

  .bottom-navbar-link{
    width: 55px;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-weight: 500;
    font-size: 10px;
    line-height: 115%;
    text-align: center;
    color: #E3E3E3;
    opacity: 0.9;
    text-decoration: none;

    svg{
      margin-bottom: 4px;
    }
  }
  .active{
    >svg{
      path{
        fill: #A482FF;
      }
    }

    .active-dot{
      width: 4px;
      height: 4px;
      background: #A482FF;
      border-radius: 50%;
      margin-top: 9px;
    }
  }
}