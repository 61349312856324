.campaigns-card {
  position: relative;
  height: 256px;
  width: 100%;
  max-width: 380px;
  border-radius: 11px;
  overflow: hidden;
  padding: 13px;
  margin: 0 auto 12px auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  cursor: pointer;

  &::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(9.32deg, #000000 21.54%, rgba(0, 0, 0, 0) 89.8%);
    z-index: 0;
  }

  .banner-wrapper {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 0;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .social-tab {
    padding: 7px 8px;
    background: #151515;
    border-radius: 6px;
    z-index: 1;
    font-weight: 700;
    font-size: 10px;
    line-height: 124.5%;
    color: #F7F6FB;
    opacity: 0.99;
    display: flex;
    align-items: center;
    margin-right: 4px;

    &:last-child {
      margin-right: 0;
    }

    svg {
      max-height: 10px;
      max-width: 12px;
      margin-right: 4px;
      margin-bottom: 1px;
    }
  }

  .details {
    position: relative;
    z-index: 1;
  }

  .title {
    position: relative;
    font-weight: 600;
    font-size: 20px;
    line-height: 124.5%;
    color: #F7F6FB;
    opacity: 0.99;
  }

  .campaign-date {
    font-weight: 600;
    font-size: 12px;
    line-height: 124.5%;
    color: #F7F6FB;
    opacity: 0.8;
    margin: 8px 0 16px 0;
  }

  .budget-amount {
    font-weight: 600;
    font-size: 20px;
    line-height: 124.5%;
    color: #F7F6FB;
    opacity: 0.99;
  }

  .view-details {
    font-weight: 600;
    font-size: 14px;
    line-height: 124.5%;
    color: #FFFDD6;
    opacity: 0.99;
    text-decoration: none;

    svg {
      height: 12px;
      margin-left: 6px;
    }
  }
}

.pending-campaign-card {
  justify-content: flex-end;
  background: rgba(45, 45, 48, 1);
  height: 196px;

  &::after {
    display: none;
  }

  .empty-line {
    font-weight: 600;
    font-size: 20px;
    line-height: 124.5%;
    color: #BD7100;
    opacity: 0.99;
  }
}