.tabs {
   overflow-x: auto;
   margin-bottom: 14px;
   padding: 0 17px;

   &::-webkit-scrollbar {
      display: none;
   }

   .tab {
      padding: 13px 18px;
      margin-right: 10px;
      background: transparent;
      border-radius: 8px;
      font-weight: 600;
      font-size: 15px;
      line-height: 115%;
      color: #FFFDD6;
      opacity: 0.6;
      flex-shrink: 0;
      transition: all 0.2s ease;
      cursor: pointer;

      &:last-child {
         margin-right: 0;
      }

      &:first-child {
         padding-left: 0;
      }
   }

   .active {
      background: #FFFDD6;
      color: #1A1823;
      font-size: 14px;
      opacity: 1;

      &:first-child {
         padding-left: 18px;
      }
   }

}

.tab-content {
   margin-bottom: 32px;



   .desc {
      font-weight: 600;
      font-size: 12px;
      line-height: 150%;
      color: #FFFFFF;
      opacity: 0.75;
   }

   .contant-button {
      margin-top: 25px;

      svg {
         margin-left: 16px;
      }
   }

   .platform-name {
      font-size: 16px;
      color: rgba($color: #FFF, $alpha: 1);
      font-weight: 700;
      text-transform: capitalize;
      margin-bottom: 8px;
   }

   .prices {
      font-size: 14px;
      color: #FFF;
      font-weight: 600;
      letter-spacing: 0.2px;
   }

   .other-info {
      color: #FFF;
      font-size: 12px;
      font-weight: 500;
      list-style-type: decimal;
      padding-left: 12px;

      li {
         margin-bottom: 12px;
      }

      .label {
         font-weight: 600;
      }
   }

}