.manage-onborarding {
  padding-top: 84px;
  padding-bottom: 28px;

  &-tabs {
    padding-bottom: 17px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    overflow-x: auto;
    display: flex;
    align-items: center;
    margin-bottom: 17px;

    &::-webkit-scrollbar {
      display: none;
    }

    .tab {
      font-weight: 500;
      font-size: 16px;
      line-height: 124.5%;
      color: #F7F6FB;
      opacity: 0.8;
      margin-right: 13px;
      cursor: pointer;
      flex-shrink: 0;

      &.active {
        font-weight: 600;
        color: #A482FF;
      }
    }
  }

  .not-found-text {
    font-size: 16px;
    font-weight: 600;
    text-align: center;
    color: #FFF;
  }

  .onboarding-detail-card {
    background: #2D2D30;
    border-radius: 11px;
    padding: 18px 13px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 6px;
    text-decoration: none;

    .logo {
      height: 41px;
      width: 41px;
      border-radius: 50%;
      margin-right: 10px;
    }

    .name {
      font-weight: 600;
      font-size: 14px;
      line-height: 115%;
      color: #FBFBFB;
      margin-bottom: 4px;
    }

    .date {
      font-weight: 500;
      font-size: 12px;
      line-height: 135%;
      color: #CFCFD0;
    }

    .question-status {
      background: #1BAB3B;
      border-radius: 6px;
      padding: 5px 9px;
      font-weight: 500;
      font-size: 10px;
      line-height: 124.5%;
      color: #FBFBFB;

      &.pending {
        background: #BD7100;
      }
    }
  }
}