.paste-link-modal {

   .link-input {
      height: 131px;
      width: 100%;
      padding: 16px;
      color: #FFF;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.36px;
      border-radius: 9px;
      border: 1px solid rgba(248, 248, 248, 0.19);
      background: #0F0D0D;
      margin-top: 18px;
      margin-bottom: 18px;

      &::placeholder {
         color: #9A9A9A;
         font-size: 12px;
         font-weight: 500;
         line-height: 150%;
         letter-spacing: -0.36px;
      }
   }

   .cancel-button {
      border-radius: 6px;
      border: 1px solid #6C6C6C;
      height: 54px;
      flex-shrink: 0;
      width: 130px;
      background: transparent;
      color: #FFF;
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.16px;
   }

   .complete-button {
      border-radius: 6px;
      background: #6B37F4;
      height: 54px;
      color: #FFF;
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.16px;
      border: none;
      flex-grow: 1;
      margin-left: 8px;

      &:focus {
         outline: none;
      }
   }
}