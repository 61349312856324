.marketing-content-wrapper{
  position: relative;
  background: #2D2D30;
  border-radius: 11px;
  padding: 15px 17px 24px 20px;
  margin-top: 11px;

  .plateform-color-line{
    position: absolute;
    left: 8px;
    top: 50%;
    transform: translateY(-50%);
    background: #1DA1F2;
    border-radius: 6.5px;
    width: 2px;
    height: 85%;
  }

  .platform-name{
    font-weight: 500;
    font-size: 13px;
    line-height: 124.5%;
    color: #F7F6FB;
    opacity: 0.8;

    svg{
      max-height: 13px;
      max-width: 15px;
      margin-right: 4px;
    }
  }

  .complete-badge{
    border: 1px solid #14B706;
    border-radius: 5px;
    padding: 5.5px 6px 5.5px 7px;
    background: transparent;
    font-weight: 500;
    font-size: 10px;
    line-height: 115%;
    color: #FFFFFF;
    opacity: 0.8;
    transition: all 0.2s ease;
    
    svG{
      height: 10.5px;
      margin-right: 3.75px;
    }
  }
  .complete-badge-acitve{
    background: #14B706;
  }

  .breaking-line{
    width: 100%;
    margin: 18px 0 10px 0;
    opacity: 0.32;
    border: 1px solid rgba(255, 253, 214, 0.28);
  }
  .breaking-line-2{
    margin: 20px 0;
  }

  .content-text{
    font-weight: 500;
    font-size: 13px;
    line-height: 124.5%;
    color: #F7F6FB;
    opacity: 0.99;
  }
  .media-title{
    font-weight: 500;
    font-size: 13px;
    line-height: 124.5%;
    color: #F7F6FB;
    opacity: 0.8;

    svg{
      margin-right: 6px;
      height: 12.5px;
      width: 12.5px;
    }
  }

  .img-attachment-wrapper{
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    column-gap: 6px;
    row-gap: 8px;
    margin-top: 12px;

    img{
      height: 58px;
      width: 57px;
      border-radius: 7px;
      object-fit: cover;
    }
  }
}
