.add-question-modal {
   overflow-y: auto;

   .modal-card-wrapper {
      position: relative;
      max-height: fit-content;
      margin: 40px 0;

      .close-modal-button {
         width: 24px;
         height: 24px;
         background: #505050;
         display: flex;
         align-items: center;
         justify-content: center;
         position: absolute;
         top: 15px;
         right: 15px;
         border-radius: 50%;
         border: none;
         cursor: pointer;
      }

      .label {
         font-weight: 600;
         font-size: 14px;
         line-height: 124.5%;
         color: #FFF;
         height: -moz-fit-content;
         height: fit-content;
         padding: 0 5px 0 5px;
         width: 100%;
         background: transparent;
         border: none;
         outline: none;
         margin-bottom: 8px;
         margin-top: 14px;
      }

      .text-input {
         width: 100%;
         background: #0F0D0D;
         border: 1px solid rgba(248, 248, 248, 0.19);
         border-radius: 9px;
         flex-grow: 1;
         font-weight: 500;
         font-size: 12px;
         line-height: 150%;
         color: #FFFFFF;
         opacity: 0.8;
         padding: 13px 19px;
         height: 38px;
         transition: all 0.2s ease;

         &:focus {
            outline: none;
            border: 1px solid #FFF;
         }
      }

      .question-type-select {
         position: relative;
         width: 100%;
         width: 100%;
         background: #0F0D0D;
         border: 1px solid rgba(248, 248, 248, 0.19);
         border-radius: 9px;
         flex-grow: 1;
         font-weight: 500;
         font-size: 12px;
         line-height: 150%;
         color: #FFFFFF;
         opacity: 0.8;
         padding: 5px 19px;
         height: 38px;
         display: flex;
         align-items: center;
         transition: all 0.2s ease;
         -webkit-appearance: none;
         -moz-appearance: none;
         box-sizing: border-box;

         &:focus {
            outline: none;
            border: 1px solid #FFF;
         }
      }

      .add-option-input {
         margin-bottom: 4px;
         height: 34px;
         border-radius: 6px;
         padding-right: 32px;
      }

      .remove-option {
         position: absolute;
         top: 12px;
         right: 14px;
         height: 10px;
         width: 10px;
         display: flex;
         align-items: center;

         img {
            height: 100%;
            width: 100%;
         }
      }

      .add-option-button {
         width: 100%;
         height: 42px;
         margin-top: 8px;
         border-radius: 6px;
         border: 1px solid #5E5E5E;
         background: #FFFDD6;
         color: #1C1827;
         font-size: 16px;
         font-weight: 500;
         line-height: 150%;
         letter-spacing: 0.16px;

         &:focus {
            outline: none;
            border: 1px solid #FFF;
         }
      }

      .add-question-button {
         height: 44px;
         margin-top: 18px;
      }
   }
}