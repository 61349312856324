.profile-social-details {
  margin-top: 19px;
  margin-bottom: 36px;

  .project-name {
    width: 220px;
    font-weight: 600;
    font-size: 20px;
    line-height: 115%;
    color: #FBFBFB;

    svg {
      margin-left: 10px;
      margin-top: 2px;
    }
  }

  .project-type {
    font-weight: 600;
    font-size: 11px;
    line-height: 135%;
    color: #BAB8BC;
    margin: 6px 0 8px 0;
    display: block;
  }

  .project-desc {
    font-weight: 600;
    font-size: 11px;
    line-height: 150%;
    color: #FFFFFF;
    opacity: 0.95;
  }

  .project-link {
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    color: #A482FF;
    text-decoration: none;
    display: flex;

    svg {
      margin-right: 5px;
    }
  }

  .project-statics {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0;
    padding-bottom: 24px;

    .statics-item {
      position: relative;
      width: 100%;

      &::after {
        position: absolute;
        content: '';
        right: 0;
        height: 30px;
        width: 1px;
        background: rgba(255, 253, 214, 0.28);
        ;
        opacity: 0.2;
        top: 50%;
        transform: translateY(-50%);
      }

      &:last-child {
        &::after {
          display: none;
        }
      }

      &-label {
        font-weight: 600;
        font-size: 12px;
        line-height: 115%;
        color: #FFFDD6;
        opacity: 0.7;
      }

      &-value {
        font-weight: 700;
        font-size: 25px;
        line-height: 115%;
        color: #FFFDD6;
        margin-top: 5px;
        width: 100px;
      }
    }
  }

  .br {
    border-bottom: 1px solid rgba(255, 253, 214, 0.28);
    width: 100%;
    opacity: 0.32;
    margin-bottom: 24px;
  }

  .socials {
    margin-bottom: 36px;

    &-links {
      height: 20px;
      width: 20px;
      margin: 0 15px;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}