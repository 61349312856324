.home {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100vh;
  padding-top: 84px;
  padding-bottom: 28px;

  .dashboard-titles {
    position: relative;
    font-size: 19px;
    font-weight: 600;
    line-height: 115%;
    color: rgba($color: #FFF, $alpha: 0.7);
    margin: 0 0 15px 0;
    width: fit-content;

    &::after {
      position: absolute;
      content: '';
      height: 2px;
      width: 72px;
      background: linear-gradient(to right, rgba($color: #FFF, $alpha: 0.7), rgba($color: #FFF, $alpha: 0));
      top: 50%;
      right: -84px;
      transform: translateY(-50%);
    }
  }

  .dashboard-cards-carousel {
    height: 196px;
    margin-bottom: 44px;

    .dashboard-card-container {
      padding: 17px 15px;
      background: #1B1A1A;
      border-radius: 6px;
      width: 100%;
      box-sizing: border-box;

      .icon-wrapper {
        height: 31px;
        width: 31px;
        margin-bottom: 30px;

        img {
          width: 100%;
        }
      }

      .title {
        font-size: 11px;
        font-weight: 600;
        line-height: 115%;
        color: #FFFFFF;
        opacity: 0.8;
      }

      .number {
        margin-top: 3px;
        font-weight: 600;
        font-size: 30px;
        line-height: 115%;
        color: #FFFFFF;
      }

      .monthly-statics {
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.03em;
        color: #9A9A9A;
        display: flex;
        align-items: center;

        span {
          color: #088B3A;
          font-weight: 600;
          margin-left: 2px;
        }
      }
    }

    .swiper-pagination-bullet {
      width: 7px !important;
      height: 7px !important;
      background: rgba(107, 55, 244, 0.11) !important;
      border: 1px solid #434345 !important;
      margin: 0 3px !important;
      transition: all 0.1s ease;
    }

    .swiper-pagination-bullet.swiper-pagination-bullet-active {
      background: #6B37F4 !important;
      border: 1px solid #6B37F4 !important;
      transition: all 0.1s ease;
    }
  }




  .manage-link-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    row-gap: 20px;
    column-gap: 10px;

    .link-container {
      width: 70px;
      text-decoration: none;

      .icon-container {
        height: 61px;
        width: 67px;
        background: #1D1D1D;
        border-radius: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .manage-link {
        font-size: 12px;
        line-height: 115%;
        text-align: center;
        color: #E3E3E3;
        opacity: 0.9;
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top: 11px;
      }
    }
  }


  .add-wrapper {
    padding: 5px 4px;
    margin: 44px 0 0 0;
    background: #1D1D1D;
    border: 1px solid rgba(255, 253, 214, 0.5);
    border-radius: 6px;
    width: 100%;
    display: none;
    transition: all 0.3s;

    &-button {
      width: 100%;
      background: transparent;
      height: 52px;
      background: #1D1D1D;
      border: none;
      border-bottom: 1px solid #373538;
      font-weight: 600;
      font-size: 14px;
      line-height: 150%;
      display: flex;
      align-items: center;
      justify-content: center;
      letter-spacing: 0.01em;
      color: #CECECE;
      text-decoration: none;
      transition: all 0.2s ease-in;

      &:last-child {
        border-bottom: none;
      }

      svg {
        margin-right: 5px;

        path {
          fill: #CECECE;
        }
      }

      &:hover {
        background: #FFFDD6;
        color: #1C1827;

        svg {
          path {
            fill: #1C1827;
          }
        }
      }

    }

    .active {
      background: #FFFDD6;
      color: #1C1827;

      svg {
        path {
          fill: #1C1827;
        }
      }
    }

  }

  .show-add-wrapper {
    display: block;
  }

  .add-button {
    width: 100%;
    height: 54px;
    background: #FFFDD6;
    border: 1px solid #5E5E5E;
    border-radius: 6px;
    border: none;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01em;
    color: #1C1827;
    margin-top: 44px;

    &:focus {
      outline: none;
    }

    svg {
      margin-right: 5px;
    }
  }

  .add-button-active {
    background: transparent;
    border: 1px solid #5E5E5E;
    color: #CECECE;
    margin-top: 6px;

    svg {
      path {
        fill: #CECECE;
      }
    }
  }


  .add-project-modal {
    .close-modal-button {
      width: 24px;
      height: 24px;
      background: #505050;
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 15px;
      right: 15px;
      border-radius: 50%;
      border: none;
      cursor: pointer;
    }

    .option {
      height: 52px;
      width: 100%;
      background: #2D2D30;
      border-radius: 9px;
      font-weight: 600;
      font-size: 14px;
      line-height: 115%;
      color: #FBFBFB;
      padding: 18px 24px;
      margin-bottom: 9px;
      cursor: pointer;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  .add-campaign-modal {
    .modal-card-wrapper {
      min-height: 300px;

      .compnay-list-wrapper {
        max-height: 480px;
        overflow-y: auto;
      }
    }

    .search-bar {
      display: flex;
      align-items: center;
      background: #0F0D0D;
      border: 1px solid rgba(248, 248, 248, 0.19);
      border-radius: 9px;
      height: 50px;
      margin-bottom: 18px;
      padding: 10px 17px;

      img {
        margin-right: 8px;
        flex-shrink: 0;
      }

      input {
        width: 100%;
        flex-grow: 1;
        font-weight: 500;
        font-size: 12px;
        line-height: 150%;
        letter-spacing: -0.03em;
        font-feature-settings: 'case' on;
        color: #FFF;
        background: transparent;
        border: none;

        &:focus {
          outline: none;
        }

        &::placeholder {
          color: #9A9A9A;
        }
      }
    }

    &-card {
      background: #2D2D30;
      border-radius: 11px;
      padding: 10px 17px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      text-decoration: none;
    }

    .logo {
      width: 54px;
      height: 54px;
      border-radius: 50px;
      margin-right: 12px;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 115%;
      color: #FBFBFB;
    }

    .question-status {
      font-weight: 500;
      font-size: 12px;
      line-height: 135%;
      color: #FF5353;
      margin-top: 4px;

      &.green {
        color: rgba(68, 219, 43, 1);
      }
    }
  }
}