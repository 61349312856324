.auth-page {
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: space-between;
   position: relative;
   min-height: 100vh;
   background: #151515;
   padding: 32px 25px;

   &::after {
      position: absolute;
      content: '';
      left: 274px;
      bottom: 100%;
      width: 508px;
      height: 508px;
      left: -291px;
      top: -508px;
      background: #6B37F4;
      filter: blur(300px);
   }

   .bottom-bg {
      position: fixed;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      max-width: 500px;
      height: 420px;
      width: 100%;
      background: url("../../static/assets/icons/Auth/page-bottom-bg.png");
      background-size: cover;
      background-position: center center;
      background-repeat: no-repeat;
      z-index: 0;
   }

   .wrapper{
      position: relative;
      z-index: 1;
   }

   .auth-input {
      max-width: 360px;
      width: 100%;
      height: 59px;
      background: #0F0D0D;
      border: 1px solid rgba(248, 248, 248, 0.19);
      border-radius: 9px;
      font-weight: 500;
      font-size: 14px;
      line-height: 150%;
      color: #FFFFFF;
      margin-bottom: 10px;
      padding: 5px 20px;
      transition: all 0.2s ease;

      &:focus {
         outline: none;
         border: 1px solid rgba(248, 248, 248, 1);
      }

      &:last-child {
         margin-bottom: 0;
      }
   }

   .error-text {
      color: #E86969;
      margin-bottom: 14px;
      font-size: 13px;
      margin-top: -5px;
      margin-left: 4px;
   }

   .ask-sign-in-text {
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #AAA9AA;
      margin-top: 27px;

      a {
         font-weight: 600;
         font-size: 16px;
         line-height: 19px;
         color: #A482FF;
         margin-left: 5px;
         text-decoration: none;
      }
   }

   .title {
      font-weight: 600;
      font-size: 24px;
      line-height: 150%;
      color: #FFFFFF;
      margin-bottom: 19px;
   }

   .logo-wrapper {
      height: 66px;
      width: 66px;
      border-radius: 50%;
      background: #6B37F4;
   }

   &.signup-page {

      .welcome-title {
         font-weight: 600;
         font-size: 16px;
         line-height: 20px;
         color: #AAA9AA;
         margin: 35px 0 5px 0;
      }

      .create-account-title {
         font-weight: 600;
         font-size: 15px;
         line-height: 124.5%;
         color: #F0F0F0;
         opacity: 0.8;
         margin-bottom: 52px;
      }

      .sign-up-button {
         max-width: 320px;
         width: 100%;
         height: 54px;
         border: 1px solid rgba(248, 248, 248, 0.19);
         border-radius: 6px;
         display: flex;
         align-items: center;
         justify-content: center;
         font-weight: 600;
         font-size: 14px;
         line-height: 140%;
         letter-spacing: 0.01em;
         color: #FFFFFF;
         margin-bottom: 9px;
         background: transparent;
         text-decoration: none;

         &:focus {
            outline: none;
         }

         img {
            margin-left: 10px;
         }
      }

      .tc-text {
         font-weight: 500;
         font-size: 12px;
         line-height: 154%;
         text-align: center;
         color: #AAA9AA;

         a {
            color: rgba(250, 246, 215, 1);
            text-decoration: none;
         }
      }
   }




   &.sign-in-page {
      padding: 66px 25px 28px 25px;

      .logo-wrapper {
         width: 51px;
         height: 49px;
         background: #6B37F4;
         border-radius: 50%;
         margin-bottom: 28px;
      }

      .welcome-title {
         font-weight: 600;
         font-size: 14px;
         line-height: 150%;
         color: #F0F0F0;
         opacity: 0.8;
      }

      .forgot-password-link {
         display: block;
         font-weight: 500;
         font-size: 12px;
         line-height: 14px;
         color: #E86969;
         margin-top: 11px;
      }

      .or-text {
         text-align: center;
         position: relative;
         margin-bottom: 36px;
         width: 100%;

         >div {
            padding: 0 16px;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
            color: rgba(255, 255, 255, 0.8);
            background: #151515;
            width: fit-content;
            position: relative;
            z-index: 1;
            margin-left: auto;
            margin-right: auto;
         }

         &::after {
            background-color: rgba(255, 255, 255, 0.5);
            content: "";
            height: 1.5px;
            left: 0;
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
            width: 100%;
            z-index: 0;
         }
      }

      .login-button {
         height: 54px;
         width: 100%;
         max-width: 360px;
         font-weight: 600;
         font-size: 16px;
         line-height: 140%;
         display: flex;
         align-items: center;
         justify-content: center;
         letter-spacing: 0.01em;
         color: #FFFFFF;
         border: none;
         cursor: pointer;
         margin-bottom: 9px;
         border-radius: 6px;

         img {
            margin-right: 18px;
         }
      }

      .fb-login-button {
         background: #1877F2;

      }

      .google-login-button {
         background: #4A494B;
      }
   }



   &.signup-with-email-page {
      padding: 80px 25px 34px 25px;
   }
}