.file-upload-button {
   min-height: 44px;
   width: 100%;
   cursor: pointer;
   background: transparent;
   border: 1px solid rgba(248, 248, 248, 0.19);
   border-radius: 6px;
   padding: 5px 16px;
   flex-grow: 1 !important;

   img {
      margin-right: 13px;
   }

   .label {
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #FFFFFF;
      opacity: 0.8;
      max-width: 200px;
   }
}

.custom-select-wrapper {
   position: relative;
   width: 100%;
   height: 44px;

   .selected-value-wrapper {
      background: #0F0D0D;
      border: 1px solid rgba(248, 248, 248, 0.19);
      border-radius: 9px;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #FFFFFF;
      padding: 20px;
   }

   .option-wrapper {
      position: absolute;
      width: 100%;
      top: 50px;
      left: 0;
      border: 1px solid rgba(248, 248, 248, 0.19);
      border-radius: 9px;
      padding: 4px 15px;
      background: #0F0D0D;
      z-index: 3;
      height: 0;
      opacity: 0;
      overflow-y: auto;
      visibility: hidden;
      transition: all 0.3s;

      &::-webkit-scrollbar {
         display: none;
      }

      .option {
         height: 38px;
         width: 100%;
         border-bottom: 1px solid rgba(248, 248, 248, 0.19);
         display: flex;
         align-items: center;
         padding-left: 5px;
         font-weight: 500;
         font-size: 14px;
         line-height: 150%;
         color: #FFFFFF;
         cursor: pointer;

         &:last-child {
            border-bottom: none;
         }

         &:hover {
            background: #2b2a2a;
         }
      }
   }

   .show-option {
      max-height: 220px;
      height: fit-content;
      opacity: 1;
      visibility: visible;
   }
}

.social-media-url-input {
   padding-left: 45px !important;
   width: 100%;

   &::placeholder {
      text-transform: capitalize;
   }
}

.social-icon-wrapper {
   position: absolute;
   top: 8px;
   left: 19px;
   height: 18px;
   width: 18px;

   >svg {
      max-width: 100%;
      max-height: 100%;
   }
}

.boolean-button {
   width: 50%;
   height: 44px;
   outline: none;
   background: transparent;
   border: 1px solid rgba(248, 248, 248, 0.19);
   border-radius: 6px;
   padding: 5px 16px;
   font-weight: 500;
   font-size: 12px;
   line-height: 150%;
   color: #FFFFFF;
   opacity: 0.8;
   transition: all 0.3s;

   &.active {
      border-color: #FFF;
   }

   &:first-child {
      margin-right: 5px;
   }
}

.text-area {
   height: 118px !important;
}

.tabs-container {
   display: flex;
   flex-wrap: wrap;

   .tab {
      background: transparent;
      width: fit-content;
      height: 34px;
      padding: 8px 14px;
      border-radius: 8px;
      border: 1px;
      margin: 4px 4px;
      font-size: 12px;
      font-weight: 500;
      line-height: 18px;
      letter-spacing: -0.03em;
      text-align: left;
      color: #FFF;
      border: 1px solid rgba(59, 58, 58, 1);
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      transition: all 0.2s;
      cursor: pointer;

      &.active {
         font-weight: 700;
         background: rgba(107, 55, 244, 1);
         border: none;
      }
   }
}

.tweets-tabs {
   flex-wrap: nowrap;
   overflow-x: scroll;
   margin-top: 12px;

   &::-webkit-scrollbar {
      display: none;
   }
}

/* .react-calendar__month-view__days__day--weekend {
 color: #d10000;
} */

.custom-date-range-picker {
   font-family: "Clash Display", "Helvetica Neue", sans-serif;
   background: transparent;

   .react-calendar__navigation {
      display: inline-flex;
      justify-content: space-between;
      width: 100%;
      color: #FFF;
      margin-bottom: 10px;
   }

   .react-calendar__navigation__label__labelText {
      color: #FFF;
      font-weight: 600;
      font-size: 17px;
      line-height: 18px;
      opacity: 0.89;
   }

   .react-calendar__navigation__arrow {
      width: 30px;
      background: transparent;
      color: #FFF;
      font-size: 22px;
   }

   .react-calendar__navigation__label,
   .react-calendar__navigation__arrow,
   .react-calendar__tile {
      background: transparent;
      outline: none;
      border: none;
   }

   .react-calendar__tile.react-calendar__year-view__months__month,
   .react-calendar__tile.react-calendar__decade-view__years__year {
      color: #FFF;
      margin: 4px 0;

      &:disabled {
         color: rgb(115, 116, 117)
      }
   }

   .react-calendar__month-view__weekdays__weekday {
      color: rgb(255, 255, 255, 0.4);
      display: flex;
      justify-content: center;
      height: 36px;
      width: 36px;
   }

   abbr[title] {
      text-decoration: none
   }

   .react-calendar__month-view__days__day {
      color: #FFF;
      height: 36px;
      width: 36px !important;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 13px;
      font-weight: 500;

      &:disabled {
         color: rgb(115, 116, 117)
      }
   }

   .react-datepicker,
   .react-datepicker__month-container {
      width: 100%;
   }

   .react-calendar__tile:enabled:hover,
   .react-calendar__tile:enabled:focus {
      background: #f8f8fa;
      color: rgb(107, 55, 244);
      border-radius: 6px;
   }

   .react-calendar__tile--now {
      background: #6f48eb33;
      border-radius: 6px;
      font-weight: bold;
      color: rgb(107, 55, 244);
   }

   .react-calendar__tile--now:enabled:hover,
   .react-calendar__tile--now:enabled:focus {
      background: #6f48eb33;
      border-radius: 6px;
      font-weight: bold;
      color: rgb(107, 55, 244);
   }

   .react-calendar__tile--hasActive:enabled:hover,
   .react-calendar__tile--hasActive:enabled:focus {
      background: #f8f8fa;
   }

   .react-calendar__tile--active {
      background: rgb(107, 55, 244);
      border-radius: 6px;
      font-weight: bold;
      color: white;
   }

   .react-calendar__tile--active:enabled:hover,
   .react-calendar__tile--active:enabled:focus {
      background: rgb(107, 55, 244);
      color: white;
   }

   .react-calendar--selectRange .react-calendar__tile--hover {
      background-color: #f8f8fa;
   }

   .react-calendar__tile--range {
      background: #f8f8fa;
      color: rgb(107, 55, 244);
      border-radius: 0;
   }

   .react-calendar__tile--rangeStart {
      border-radius: 18px 0px 0px 18px !important;
      background: rgb(107, 55, 244);
      color: white;
   }

   .react-calendar__tile--rangeEnd {
      border-radius: 0 18px 18px 0 !important;
      background: rgb(107, 55, 244);
      color: white;
   }
}

.date-picker-modal {
   position: fixed;
   top: 0;
   left: 0;
   width: 100%;
   height: 100vh;
   background: rgba($color: #000000, $alpha: 0.5);
   backdrop-filter: blur(12px);
   display: flex;
   align-items: center;
   justify-content: center;
   flex-direction: column;
   z-index: 111;
   padding: 0 20px;
   opacity: 0;
   visibility: hidden;
   transition: all 0.3s;

   &.active {
      opacity: 1;
      visibility: visible;
   }

   .wrapper {
      background: #2D2D30;
      border-radius: 8px;
      padding: 27px 12px;
      position: relative;

      .title {
         font-weight: 600;
         font-size: 16px;
         line-height: 115%;
         color: #F0F0F0;
         position: relative;
         width: fit-content;

         &::after {
            position: absolute;
            content: '';
            top: 50%;
            transform: translateY(-50%);
            right: -84px;
            width: 72px;
            height: 2px;
            background: linear-gradient(89.96deg, #FBFBFB -7.96%, rgba(251, 251, 251, 0) 81.76%);
         }
      }

      .close-modal-button {
         width: 24px;
         height: 24px;
         background: #505050;
         display: flex;
         align-items: center;
         justify-content: center;
         border-radius: 50%;
         border: none;
         cursor: pointer;
      }
   }
}

.date-label {
   color: #838383;
   font-size: 12px;
   font-weight: 500;
   line-height: 124.5%;
   margin-bottom: 12px;
   margin-top: 15px;
}

.date-text-wrapper {
   display: flex;
   padding: 13px 19px;
   align-items: center;
   border-radius: 9px;
   border: 1px solid rgba(248, 248, 248, 0.19);
   background: #0F0D0D;
   height: 44px;
   width: 100%;
   color: #FFF;
   font-size: 12px;
   font-weight: 700;
   line-height: 124.5%;
   letter-spacing: 0.5px;

   img {
      margin-right: 10px;
   }
}



.tweet-content {
   .input-label {
      color: #838383;
      font-size: 12px;
      font-weight: 500;
      line-height: 124.5%;
      margin-bottom: 12px;
      margin-top: 20px;
   }

   .content-text-area {
      height: 132px !important;
      width: 100%;
      background: #0F0D0D;
      border: 1px solid rgba(248, 248, 248, 0.19);
      border-radius: 9px;
      flex-grow: 1;
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #FFFFFF;
      opacity: 0.8;
      padding: 13px 19px;
      height: 44px;
      transition: all 0.2s ease;

      &:focus {
         border: 1px solid #FFF;
         outline: none;
      }

      &::placeholder {
         font-weight: 500;
         font-size: 12px;
         line-height: 150%;
         color: #FFFFFF;
      }
   }
}

.add-thread-title {
   color: #9371EE;
   font-size: 12px;
   font-weight: 600;
   line-height: 150%;
   letter-spacing: -0.36px;
   margin-top: 16px;
   cursor: pointer;

   svg {
      margin-right: 7px;
   }
}

.remove-thread {
   color: #E86969;
   font-size: 12px;
   font-weight: 600;
   height: 16px;
   width: 16px;
   display: flex;
   align-items: center;
   justify-content: center;
   border: 1px solid #E86969;
   border-radius: 50%;
   cursor: pointer;
   margin-bottom: 12px;
}

.MuiTypography-root.MuiTypography-body2.css-e784if-MuiTypography-root {
   display: none !important;
}

.date-and-time-picker {
   color: #FFF !important;

   .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-colorPrimary.MuiInputBase-formControl.Mui-readOnly.MuiInputBase-readOnly.css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
      outline: none !important;
   }

   .MuiStack-root .css-rqb3oc-MuiStack-root {
      padding-top: 0 !important;
   }

   .MuiInputBase-input.MuiOutlinedInput-input,
   .MuiInputBase-input.MuiOutlinedInput-input:hover {
      height: 44px !important;
      border-radius: 9px !important;
      border: 1px solid rgba(248, 248, 248, 0.19) !important;
      padding: 0 16px !important;
      color: #FFF;
      font-size: 12px;
      font-weight: 500;
   }

   .css-1qes3pk-MuiStack-root {
      margin-top: -8px !important;
   }

   .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root:hover {
      border: none !important;
      background: transparent !important;
   }
}