.header {
  position: fixed;
  top: 0;
  left: 0;
  padding: 14px 17px 0 17px;
  width: 100%;
  background: #020202;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99;
  box-sizing: border-box;

  &.header-bg {
    background: #212121 !important;
  }

  .header-container {
    position: relative;
    width: 100%;
    height: 54px;
    padding-bottom: 16px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    box-sizing: border-box;

    .header-title {
      font-weight: 600;
      font-size: 16px;
      line-height: 124.5%;
      color: #FFFFFF;
      opacity: 0.99;
    }

    .back-button {
      width: 38px;
      height: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 1px solid rgba(255, 253, 214, 0.6);
      border-radius: 11px;

      svg {
        path {
          fill: rgba(255, 253, 214, 0.6);
        }
      }
    }
  }

  &-profile-img-wrapper {
    position: absolute;
    right: 0;
    top: 0;
    width: 38px;
    height: 38px;
    border-radius: 50%;
    background: url("../../../static/assets/icons/navbar/profile-img-border.svg");
    background-size: 100% 100%;
    background-repeat: no-repeat;
    background-position: center center;
    padding: 3px;
    box-sizing: border-box;
    cursor: pointer;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }

  &-logo {
    font-weight: 600;
    font-size: 17px;
    line-height: 124.5%;
    color: #B99EFF;
    text-decoration: none;

    &:hover {
      color: #B99EFF;
    }
  }
}

.profile-img-wrapper {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  background: url("../../../static/assets/icons/navbar/profile-img-border.svg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 3px;
  box-sizing: #B99EFF;
  cursor: pointer;

  img {
    height: 100%;
    width: 100%;
    border-radius: 50%;
  }
}