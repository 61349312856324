
.deep-link{
  padding-top: 84px;
  padding-bottom: 28px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  min-height: 100vh;

  .title{
    font-weight: 600;
    font-size: 24px;
    line-height: 115%;
    color: #FFFFFF;
    margin: 18px 0;
  }
  .desc{
    font-size: 15px;
    line-height: 115%;
    text-align: center;
    color: rgba(255, 255, 255, 0.8);
  }
  .copy-button{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    gap: 10px;
    width: 100%;
    height: 54px;
    border: 1px solid #5E5E5E;
    border-radius: 6px;
    margin-top: 33px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    color: #CECECE;
    background: transparent;
    &:focus{
      outline: none;
    }
  }
}