.influencer-social-details {
  margin-top: 24px;
  margin-bottom: 30px;

  .project-name {
    font-weight: 600;
    font-size: 20px;
    line-height: 115%;
    color: #FBFBFB;
    width: 200px;

    svg {
      margin-left: 10px;
      margin-top: 2px;
    }
  }

  .project-type {
    font-weight: 600;
    font-size: 11px;
    line-height: 135%;
    color: #BAB8BC;
    margin: 6px 0 12px 0;
  }

  .project-desc {
    font-weight: 600;
    font-size: 11px;
    line-height: 150%;
    color: #FFFFFF;
    opacity: 0.95;
  }

  .project-link {
    font-weight: 600;
    font-size: 12px;
    line-height: 135%;
    color: #A482FF;
    text-decoration: none;
    display: flex;

    svg {
      margin-right: 5px;
    }
  }

  .influencer-statics {
    padding-bottom: 40px;

    .statics-item {
      position: relative;
      background: #2D2D30;
      border-radius: 6px;
      padding: 17px 10px 15px 10px;
      cursor: pointer;
      min-width: 80px;

      &:nth-child(2) {
        margin: 0 5px;
      }

      &-label {
        font-weight: 500;
        font-size: 11px;
        line-height: 115%;
        color: #FFFDD6;
        opacity: 0.7;
      }

      &-value {
        font-weight: 700;
        font-size: 16px;
        line-height: 115%;
        color: #FFFDD6;
        margin-top: 7px;
        width: 100px;

        div {
          font-weight: 500;
          font-size: 10px;
          line-height: 115%;
          text-decoration-line: underline;
          color: #FFFDD6;
          margin-left: 8px;
        }
      }
    }
  }

  .br {
    border-bottom: 1px solid rgba(255, 253, 214, 0.28);
    width: 100%;
    opacity: 0.32;
    margin-bottom: 24px;
  }

  .socials {
    margin-bottom: 36px;

    &-links {
      height: 20px;
      width: 20px;
      margin: 0 15px;

      &:last-child {
        margin-right: 0;
      }

      &:first-child {
        margin-left: 0;
      }

      svg {
        height: 20px;
        width: 20px;
      }
    }
  }
}