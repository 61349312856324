.influencer-detail-card{
  padding: 14px 12px;
  background: #2D2D30;
  border-radius: 11px;
  margin-bottom: 7px;

  .profile-image-wrapper{
    height: 58px;
    width: 58px;
    border-radius: 50px;
    margin-right: 14px;
    img{
      height: 100%;
      width: 100%;
      border-radius: 50px;
    }
  }

  .name{
    font-weight: 600;
    font-size: 15px;
    line-height: 115%;
    color: #FBFBFB;
    margin-bottom: 4px;
  }
  .influencer-type{
    font-weight: 600;
    font-size: 10px;
    line-height: 135%;
    color: #1DA1F2;
    .social-icon{
      max-width: 12px;
      margin-right: 4px;
    }
  }

  .budget-amount{
    font-weight: 700;
    font-size: 17px;
    line-height: 124.5%;
    color: #F7F6FB;
    opacity: 0.9;
  }

  .br{
    width: 100%;
    opacity: 0.32;
    border: 1px solid rgba(255, 253, 214, 0.28);
    margin: 13px 0;
  }

  .social-tab{
    padding: 7px 8px;
    background: #151515;
    border-radius: 6px;
    z-index: 1;
    font-weight: 700;
    font-size: 10px;
    line-height: 124.5%;
    color: #F7F6FB;
    opacity: 0.99;
    display: flex;
    align-items: center;
    margin-right: 4px;

    &:last-child{
      margin-right: 0;
    }
    svg{
      max-height: 10px;
      max-width: 12px;
      margin-right: 4px;
      margin-bottom: 1px;
    }
  }

  .cta-button{
    border: 0.5px solid #ACACAC;
    border-radius: 6px;
    height: 39px;
    width: 100%;
    margin-top: 15px;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    text-decoration: none;

    &:focus{
      outline: none;
    }

    svg{
      margin-left: 6px;
      margin-bottom: 1px;
    }
  }
}