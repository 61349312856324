.onboarding-details-page {
  padding-top: 96px;
  padding-bottom: 28px;

  .onboarding-company-details {
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    margin-bottom: 30px;
    display: flex;
    align-items: center;

    .company-logo {
      height: 85px;
      width: 85px;
      border-radius: 21px;
      margin-right: 14px;
    }

    .name {
      font-weight: 600;
      font-size: 16px;
      line-height: 115%;
      color: #FBFBFB;
      text-transform: capitalize;
    }

    .date {
      font-weight: 500;
      font-size: 12px;
      line-height: 135%;
      color: #CFCFD0;
      margin: 5px 0 9px 0;
    }

    .meeting-date {
      background: #1BAB3B;
      border-radius: 5px;
      font-weight: 600;
      font-size: 12px;
      line-height: 124.5%;
      padding: 5px 9px;
      color: #FBFBFB;
      width: fit-content;
    }
  }

  .title {
    position: relative;
    width: fit-content;
    font-weight: 600;
    font-size: 19px;
    line-height: 115%;
    color: #FFFFFF;
    margin-bottom: 30px;

    &::after {
      position: absolute;
      content: '';
      height: 2px;
      width: 72px;
      background: linear-gradient(89.96deg, #FBFBFB -7.96%, rgba(251, 251, 251, 0) 81.76%);
      right: -84px;
      top: 50%;
      transform: translateY(-50%);
    }

  }

  .br {
    width: 100%;
    margin-bottom: 18px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
  }

  .onboarding-marketing-tab {
    background: #2D2D30;
    border-radius: 9px;
    font-weight: 500;
    font-size: 16px;
    line-height: 124.5%;
    color: #FBFBFB;
    padding: 9px 17px;
    margin-right: 8px;
    cursor: pointer;

    &.active {
      background: rgba(107, 55, 244, 1);
    }
  }
}