.campaigns-page {
  min-height: 100vh;
  background: #151515;
  position: relative;
  padding: 84px 17px 28px 17px;

  .bg {
    position: fixed;
    left: -284px;
    bottom: 100%;
    width: 508px;
    height: 508px;
    background: #6B37F4;
    filter: blur(300px);
  }

  &-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 17px;

    .back-button {
      width: 41px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 1px solid #FFFDD6;
      border-radius: 6px;
    }

    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 124.5%;
      color: #FFFFFF;
      opacity: 0.99;
    }

  }
}