.termination-modal{
  textarea{
    background: #0F0D0D;
    border: 1px solid rgba(248, 248, 248, 0.19);
    border-radius: 9px;
    padding: 16px 11px 16px 17px;
    min-height: 130px;
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    letter-spacing: -0.03em;
    font-feature-settings: 'case' on;
    color: #FFF;
    width: 100%;
    
    &::placeholder{
      color: #9A9A9A;
    }
  }
  .cancel-button{
    flex-grow: 1;
    height: 54px;
    width: 50%;
    border: 1px solid #6C6C6C;
    border-radius: 6px;
    background: transparent;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    margin-right: 4px;
    &:focus{
      outline: none;
    }
  }
  .terminate-button{
    flex-grow: 1;
    width: 50%;
    height: 54px;
    font-weight: 600;
    font-size: 16px;
    line-height: 150%;
    display: flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 0.01em;
    color: #FFFFFF;
    background: #CD0101;
    border-radius: 6px;
    border: none;
    margin-left: 4px;
    cursor: pointer;

    &:focus{
      outline: none;
    }
  }
}