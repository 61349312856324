.campaigns-details {
  background: #151515;

  .banner-wrapper {
    position: relative;
    height: 290px;
    width: 100%;
    padding: 24px 17px 22px 17px;

    .banner-img {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      object-fit: cover;
      z-index: 0;
    }

    &::after {
      position: absolute;
      content: '';
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      background: linear-gradient(9.32deg, #000000 21.54%, rgba(0, 0, 0, 0) 89.8%);
    }

    .banner-details {
      position: relative;
      z-index: 1;
    }

    .back-button {
      width: 41px;
      height: 38px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: transparent;
      border: 1px solid #FFFDD6;
      border-radius: 6px;
    }

    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 124.5%;
      color: #F7F6FB;
      opacity: 0.99;
    }

    .campaign-date-breket {
      font-size: 12px;
      line-height: 124.5%;
      color: #F7F6FB;
      opacity: 0.8;
      margin: 8px 0 16px 0;
    }

    .budget {
      font-weight: 700;
      font-size: 20px;
      line-height: 124.5%;
      color: #F7F6FB;
      opacity: 0.9;
    }
  }

  .campaigns-details-wrapper {
    padding: 28px 17px;

    .title {
      font-weight: 600;
      font-size: 20px;
      line-height: 124.5%;
      color: #F7F6FB;
      opacity: 0.99;
      margin-top: 27px;
      margin-bottom: 11px;

      &:first-child {
        margin-top: 0;
      }
    }

    .desc {
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #FFFFFF;
      opacity: 0.75;
    }

    .platform-wrapper {
      height: 28px;
      width: 32px;
      background: #1D1D1D;
      border: 1px solid rgba(248, 248, 248, 0.19);
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 8px;

      svg {
        max-height: 15px;
        max-width: 16px;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }

  .termination-button {
    max-width: 400px;
    width: 100%;
    height: 52px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    font-size: 14px;
    line-height: 150%;
    letter-spacing: 0.01em;
    color: #EC4141;
    background: transparent;
    border: 1px solid rgba(234, 104, 104, 1);
    border-radius: 7px;
    margin-top: 38px;

    &:focus {
      outline: none;
    }

    img {
      margin-right: 4px;
    }
  }

  .terminate-reason-title {
    font-weight: 600;
    font-size: 20px;
    line-height: 124.5%;
    color: #FF3E3E;
    opacity: 0.99;
    margin-bottom: 11px;
  }

  .termination-reason {
    font-weight: 500;
    font-size: 12px;
    line-height: 150%;
    color: #FFE6E6;
    opacity: 0.75;
  }
}