.recommanded-influncers {
  padding-top: 84px;
  padding-bottom: 90px;
  min-height: 100vh;

  .next-button-container {
    position: fixed;
    bottom: 28px;
    left: 0;
    z-index: 3;
    padding: 0 17px;
    width: 100%;

    .next-button {
      width: 100%;
    }
  }

  .search-and-filter-bar {
    margin-bottom: 30px;

    .title {
      color: #FFF;
      font-size: 19px;
      font-weight: 600;
      line-height: 115%;
      margin-bottom: 22px;
    }

    .search-input-wrapper {
      position: relative;
      border-radius: 9px;
      border: 1px solid rgba(248, 248, 248, 0.19);
      background: #0F0D0D;
      height: 50px;
      padding: 10px 17px;
      flex-grow: 1;

      svg {
        margin-right: 8px;
      }

      input {
        width: 100%;
        height: 100%;
        color: #9A9A9A;
        font-size: 12px;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: -0.36px;
        background: transparent;
        padding: 0;
        border: none;
        outline: none;
      }
    }

    .filter-button {
      height: 50px;
      width: 52px;
      border-radius: 9px;
      border: 1px solid rgba(248, 248, 248, 0.19);
      background: #0F0D0D;
      margin-left: 5px;
    }
  }

  .filter-selected-items {
    display: flex;
    align-items: center;
    overflow-x: auto;
    margin-top: 10px;

    &::-webkit-scrollbar {
      display: none;
    }

    .item-box {
      color: #FFF;
      font-size: 12px;
      font-weight: 500;
      line-height: 150%;
      letter-spacing: -0.36px;
      border-radius: 8px;
      padding: 8px;
      background: #473A69;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 6px;

      svg {
        cursor: pointer;
        margin-left: 6px;
      }
    }
  }
}


.filter-wrapper {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 6px;
  background: rgba(0, 0, 0, 0.72);
  backdrop-filter: blur(15.5px);
  opacity: 0;
  visibility: hidden;
  transition: all 0.06s;
  z-index: 100;

  &.active {
    opacity: 1;
    visibility: visible;
  }

  &-card {
    position: absolute;
    bottom: -100%;
    left: 0;
    width: 100%;
    max-height: 90vh;
    border-radius: 20px 20px 6px 6px;
    background: #2D2D30;
    padding: 16px 18px 30px 18px;
    overflow-y: auto;
    transition: all 0.25s;

    &.active {
      bottom: 0;
    }

    &::-webkit-scrollbar {
      display: none;
    }

    .close-filter-tab {
      border-radius: 10px;
      background: #1A1C1E;
      margin-bottom: 16px;
      width: 59px;
      height: 6px;
      margin-left: auto;
      margin-right: auto;
    }

    .filter-title {
      color: #FFF;
      font-size: 20px;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.4px;
    }

    .reset {
      color: #7B61FF;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.28px;
    }

    .filter-section-title {
      color: #FFF;
      font-size: 16px;
      font-weight: 700;
      line-height: 150%;
      letter-spacing: -0.32px;
    }

    .check-box-wrapper {
      margin-top: 18px;
      margin-bottom: 46px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      row-gap: 22px;
      column-gap: 40px;
    }

    .checkbox-custom {
      opacity: 0;
      position: absolute;
    }

    .checkbox-custom,
    .checkbox-custom-label {
      display: inline-block;
      cursor: pointer;
      color: #FFF;
      font-size: 14px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: -0.28px;
    }

    .checkbox-custom+.checkbox-custom-label:before {
      content: '';
      border: 2px solid #ddd;
      display: inline-block;
      vertical-align: middle;
      width: 24px;
      height: 24px;
      margin-right: 12px;
      border: 1.5px solid #e9e9ea;
      border-radius: 50%;
      background: url("../../../static/assets/icons/Common/check-icon.svg");
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 0%;
      transition: all 0.1s;
    }

    .checkbox-custom:checked+.checkbox-custom-label:before {
      content: "";
      background-size: 100%;
      border-color: #7B61FF;
    }

    .filter-apply-button {
      width: 100%;
      height: 56px;
      padding: 15px;
      border-radius: 6px;
      background: #6B37F4;
      color: #FFF;
      font-size: 16px;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: 0.16px;
      border: none;

      &:focus {
        outline: none;
      }
    }
  }
}