.status-badge {
   padding: 5px 9px;
   border-radius: 5px;
   font-weight: 500;
   font-size: 10px;
   line-height: 124.5%;
   width: fit-content;
   display: flex;
   align-items: center;
   justify-content: center;
   color: #FFF;
   max-height: 22px;
   flex-shrink: 0;

   svg {
      margin-right: 2px;
      height: 14px;
      width: 14px;
      margin-bottom: 1px;
   }
}

.pending {
   color: #FBFBFB;
   background: #BD7100;
}

.completed {
   background: #4A58CC;
}

.success {
   background: rgba(27, 171, 59, 1);
}

.terminated {
   background: rgba(183, 6, 6, 1);
}

.font-600 {
   font-weight: 600;
}

.h-12 {

   svg {
      height: 12.5px;
      width: 12.5px;
   }
}