@font-face {
  font-family: "Clash Display";
  src: url("../static/fonts/ClashDisplay-Bold.woff") format("truetype");
  font-style: normal;
  font-weight: 700;
  font-display: swap;
}

@font-face {
  font-family: "Clash Display";
  src: url("../static/fonts/ClashDisplay-Semibold.woff") format("truetype");
  font-style: normal;
  font-weight: 600;
  font-display: swap;
}

@font-face {
  font-family: "Clash Display";
  src: url("../static/fonts/ClashDisplay-Medium.ttf") format("truetype");
  font-style: normal;
  font-weight: 500;
  font-display: swap;
}

@font-face {
  font-family: "Clash Display";
  src: url("../static/fonts/ClashDisplay-Light.woff") format("truetype");
  font-style: normal;
  font-weight: 400;
  font-display: swap;
}

#root {
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

body {
  margin: 0;
  width: 100%;
  font-family: 'Clash Display', 'Helvetica Neue',
    sans-serif;
  font-weight: 500;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #110E12;
  box-sizing: border-box;
}

.main-container {
  min-height: 100vh;
  padding: 0 17px;
}

button {
  &:focus {
    outline: none;
  }
}

.not-found-text {
  text-align: center;
  font-weight: 600;
  font-size: 15px;
  line-height: 115%;
  color: #FBFBFB;
}

.common-button {
  width: 100%;
  height: 54px;
  background: #6B37F4;
  border-radius: 6px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  color: #FFFFFF;
  cursor: pointer;
  text-decoration: none;
  transition: all 0.2s ease;

  &:focus {
    outline: none;
  }

  &:hover {
    color: rgba($color: #FFF, $alpha: 0.9);
  }

  &:disabled {
    background: #6f6a6a;
  }
}

.custom-modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, 0.72);
  backdrop-filter: blur(15.5px);
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  z-index: 101;
  visibility: hidden;
  opacity: 0;
  transition: all 0.4s;

  &.show-modal {
    opacity: 1;
    visibility: visible;
  }

  .modal-card-wrapper {
    background: #1B1A1A;
    box-shadow: 0px 26px 30px rgba(101, 101, 101, 0.19);
    border-radius: 13px;
    padding: 20px 15px;
    max-width: 500px;
    width: 100%;
    max-height: 80%;
    margin: 0 16px;

    .title {
      font-weight: 600;
      font-size: 19px;
      line-height: 115%;
      color: #FFFFFF;
      position: relative;
      width: fit-content;
      margin-bottom: 19px;

      &::after {
        position: absolute;
        content: '';
        background: linear-gradient(89.96deg, #FBFBFB -7.96%, rgba(251, 251, 251, 0) 81.76%);
        width: 72px;
        height: 2px;
        right: -84px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }
}