.meeting-calender-page {
   padding-top: 84px;
   padding-bottom: 28px;

   .title {
      font-weight: 600;
      font-size: 22px;
      line-height: 115%;
      color: #FBFBFB;
      margin-bottom: 26px;
   }

   .event-calender {
      padding-bottom: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.16);
      margin-bottom: 25px;
   }

   .selected-date {
      font-weight: 600;
      font-size: 16px;
      line-height: 124.5%;
      color: #F7F6FB;
      opacity: 0.99;
      margin-bottom: 14px;
   }

   .meeting-details-card {
      width: 100%;
      height: 72px;
      background: #2D2D30;
      border-radius: 9px;
      padding: 15px 10px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 7px;

      .logo {
         width: 41px;
         height: 41px;
         border-radius: 50%;
         margin-right: 10px;
      }

      .company-name {
         font-weight: 600;
         font-size: 16px;
         line-height: 115%;
         color: #FBFBFB;
      }

      .time {
         font-weight: 600;
         font-size: 12px;
         line-height: 140%;
         letter-spacing: 0.2px;
         color: #D2D2D2;
         margin-top: 4px;
      }

      .join-button {
         width: 74px;
         height: 29px;
         background: #6B37F4;
         border-radius: 6px;
         font-weight: 600;
         font-size: 14px;
         line-height: 150%;
         display: flex;
         align-items: center;
         justify-content: center;
         letter-spacing: 0.01em;
         color: #FFFFFF;
         text-decoration: none;
         border: none;
         cursor: pointer;

         &:focus {
            outline: none;
         }
      }
   }
}