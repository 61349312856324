.review-proposal{
  padding-top: 84px;
  padding-bottom: 28px;
  min-height: 100vh;
  .questions-answer-wrapper{
    margin-bottom: 18px;
  
    .question-container{
      margin-bottom: 28px;
  
      .question-number{
        font-weight: 600;
        font-size: 16px;
        line-height: 124.5%;
        color: #FFF;
        margin-right: 5px;
      }
      .question{
        font-weight: 600;
        font-size: 16px;
        line-height: 124.5%;
        color: #FFF;
      }
      .answer{
        font-weight: 500;
        font-size: 14px;
        line-height: 141%;
        color: #FFFFFF;
        opacity: 0.85;
        margin-top: 9px;
      }
    }
  }

  
  .influencer-number{
    font-weight: 600;
    font-size: 16px;
    line-height: 124.5%;
    color: #FFFFFF;
    margin-bottom: 9px;
    margin-top: 16px;
  }

  .proposal-button{
    margin-top: 30px;
  }
}