.editable-question-wrapper {
   margin-bottom: 16px;
   display: flex;

   .number {
      font-weight: 600;
      font-size: 16px;
      line-height: 124.5%;
      color: #FFFFFF;
      margin-right: 7px;
      margin-top: 2.5px;
   }

   .question {
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 124.5%;
      color: #FFFFFF;
      border: none;
      padding: 0;
      width: 100%;
      background: transparent;


      &:focus {
         outline: none;
      }
   }

   .answer {
      font-weight: 500;
      font-size: 14px;
      line-height: 141%;
      color: #FFFFFF;
      opacity: 0.85;
      background: transparent;
      border: none;
      padding: 0;
      width: 100%;
      height: fit-content;

      &:focus {
         outline: none;
      }
   }
}