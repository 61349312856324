.received-answer {
  padding-top: 84px;
  padding-bottom: 28px;
  min-height: 100vh;

  .edit-button {
    background: #1C2252;
    border: 1px solid rgba(101, 99, 221, 0.19);
    border-radius: 9px;
    width: 44px;
    height: 44px;

    &:focus {
      outline: none;
    }
  }

  .title {
    font-weight: 600;
    font-size: 19px;
    line-height: 115%;
    color: #FFFFFF;
    position: relative;
    width: fit-content;

    &::after {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      right: -84px;
      height: 2px;
      width: 72px;
      background: linear-gradient(89.96deg, #FBFBFB -7.96%, rgba(251, 251, 251, 0) 81.76%);
    }
  }

  .questions-answer-wrapper {
    margin-bottom: 20px;

    .question-container {
      margin-bottom: 28px;

      .question-number {
        font-weight: 600;
        font-size: 16px;
        line-height: 124.5%;
        color: #FFF;
        margin-right: 5px;
      }

      .question {
        font-weight: 600;
        font-size: 16px;
        line-height: 124.5%;
        color: #FFF;
      }

      .answer {
        font-weight: 500;
        font-size: 14px;
        line-height: 141%;
        color: #FFFFFF;
        opacity: 0.85;
        margin-top: 9px;
      }
    }
  }
}