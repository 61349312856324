.campaign-details-page{
  padding-top: 96px;
  padding-bottom: 28px;
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  .company-details{
    padding-bottom: 20px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.16);
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    .logo{
      width: 85px;
      height: 85px;
      border-radius: 28.5px;
      margin-right: 14px;
    }
    .name{
      font-weight: 600;
      font-size: 16px;
      line-height: 115%;
      color: #FBFBFB;
    }
    .onboard-date{
      font-weight: 500;
      font-size: 12px;
      line-height: 135%;
      color: #CFCFD0;
      margin-top: 5px;
    }
  }

  .title{
    font-weight: 600;
    font-size: 19px;
    line-height: 115%;
    color: #FFFFFF;
    position: relative;
    width: fit-content;

    &::after{
      position: absolute;
      content: '';
      height: 2px;
      width: 72px;
      right: -84px;
      top: 50%;
      transform: translateY(-50%);
      background: linear-gradient(89.96deg, #FBFBFB -7.96%, rgba(251, 251, 251, 0) 81.76%);
    }
  }
  .edit-button{
    width: 44px;
    height: 44px;
    background: #1C2252;
    border: 1px solid rgba(101, 99, 221, 0.19);
    border-radius: 9px;
    display: flex;
    align-items: center;
    justify-content: center;
    
    &:focus{
      outline: none;
    }
  }
}