.search-profile-page {
   background: rgba(21, 21, 21, 1);
   padding-top: 84px;
   padding-bottom: 28px;

   .search-input-wrapper {
      position: relative;
      margin-bottom: 9px;
      width: 100%;
      height: 50px;
      border-radius: 9px;
      border: 1px;
      padding: 0 17px 0 40px;
      border: 1px solid rgba(248, 248, 248, 0.19);
      background: linear-gradient(0deg, #0F0D0D, #0F0D0D), linear-gradient(0deg, rgba(248, 248, 248, 0.19), rgba(248, 248, 248, 0.19));

      input {
         width: 100%;
         padding: 16px 0;
         background: transparent;
         font-size: 12px;
         font-weight: 500;
         line-height: 18px;
         letter-spacing: -0.03em;
         text-align: left;
         border: none;
         color: rgba(154, 154, 154, 1);

         &:focus {
            outline: none;
         }
      }

      img {
         position: absolute;
         left: 17px;
         top: 16px;
         height: 16px;
      }
   }

   .onboarded-detail-card {
      background: #2D2D30;
      border-radius: 11px;
      padding: 18px 13px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 6px;
      text-decoration: none;

      .logo {
         height: 41px;
         width: 41px;
         border-radius: 50%;
         margin-right: 10px;
      }

      .name {
         font-weight: 600;
         font-size: 14px;
         line-height: 115%;
         color: #FBFBFB;
         margin-bottom: 4px;
      }

      .date {
         font-weight: 500;
         font-size: 12px;
         line-height: 135%;
         color: #CFCFD0;
      }

      .question-status {
         background: #1BAB3B;
         border-radius: 6px;
         padding: 5px 9px;
         font-weight: 500;
         font-size: 10px;
         line-height: 124.5%;
         color: #FBFBFB;

         &.pending {
            background: #BD7100;
         }
      }
   }
}